<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="临时停手术设置"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="医生" prop="doctor">
                <radio-doctor v-model="form.doctor"/>
            </el-form-item>
            <el-form-item label="手术室" prop="area">
                <checkbox-area v-model="form.area" :doctor="form.doctor"/>
            </el-form-item>
            <el-form-item label="值班日期" prop="dates">
                <select-dates v-model="form.dates"/>
            </el-form-item>
            <!--            <el-form-item label="时间段" prop="times">-->
            <!--                <el-checkbox-group v-model="form.times">-->
            <!--                    <el-checkbox :label="w" v-for="(w, i) in timeList" :key="i">{{w}}</el-checkbox>-->
            <!--                </el-checkbox-group>-->
            <!--            </el-form-item>-->
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保 存</el-button>
                <el-button @click="$router.go(-1)">返 回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        watch: {
            // eslint-disable-next-line no-unused-vars
            '$route'(to, from) {
                this.getData()
            }
        },
        data() {
            return {
                loading: false,
                timeList: [],
                form: {doctor: '', area: [], dates: [], times: []},
                rules: {
                    doctor: [
                        {required: true, message: '请选择医生', trigger: 'change'}
                    ],
                    area: [
                        {required: true, message: '请选择手术区', trigger: 'blur'}
                    ],
                    dates: [
                        {required: true, message: '请选择日期', trigger: 'blur'},
                    ],
                    // times: [
                    //     {required: true, message: '请选择时间段', trigger: 'blur'},
                    // ],
                }
            };
        },
        methods: {
            async submitForm(formName) {
                console.log(formName, this.form)
                const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
                if (!valid) {
                    return
                }
                const resp = await this.$http.restPostOrPut('/surgery/special/', this.form)
                if (resp.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/worksheet/special")
                } else {
                    this.$message.error(resp.msg)
                }
            },
            async getData() {
                this.loading = true
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/surgery/special/${id}`)
                    this.form = resp.data.data
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
